import { Alert, Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { examineeServiceTypeIds, REFERRAL_STATUS_DISPLAYS, isCode92ServiceType, includeOriginalReferralFields } from "../../constants/referralConstants";
import { Link as RouterLink } from "react-router-dom";
import useUser from "../../hooks/useUser";
import InjuredEmployeeDetails, { getAddressText } from "./InjuredEmployeeDetails";
import WorkplaceDetails from "./WorkplaceDetails";
import ManagerDetails from "./ManagerDetails";
import TreatingDoctorDetails from "./TreatingDoctorDetails";
import ComcareClaimDetails from "./ComcareClaimDetails";
import RCMDetails from "./RCMDetails";
import { renderAssessorCheckList } from "./RehabAssessmentDetails";
import useAuth from "../../hooks/useAuth";
import { ROLE_SUPPLIER } from "../../constants/userConstants";
  
const NonCompensationDetails = ({ referral }) => {
    const { user } = useAuth();
    const { role: userType } = user || {};
    const { userTypeNavLinks } = useUser();
    const dashboardLink = userTypeNavLinks.filter((link) => link.key === 'dashboard')[0];
    const dashboardUrl = dashboardLink ? dashboardLink.url : undefined;
    const { detail, type, providerName = "", providerAddress, serviceTypeId } = referral;
    const {
        medicalSpecialistName: medSpecialistType,
        assessor1Name: assessors1Name,
        assessorPhone: assessorsPhone,
        rehabAssessmentTime,
        rehabAuthorisedAssessmentCost,
    } = detail || {};

    return (
        <Box
            sx={{
                margin: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
            }}
        >
            <Typography variant="h1" sx={{ mb: 2 }}>Referral for services - ORAMS Head Agreement</Typography>
            <Grid container spacing={1} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Referral ID</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{referral.id}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Date created</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{dayjs(referral.created).format('DD MMM YYYY')}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Status</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{REFERRAL_STATUS_DISPLAYS[referral.status]}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography><RouterLink to={dashboardUrl}>Back to dashboard</RouterLink></Typography>
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h2">Referral information</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="p" variant="body"><b>Assessor - name of organisation and address</b></Typography>
                    <Typography component="p" variant="body2">{providerName}</Typography>
                    <Typography component="p" variant="body2">{getAddressText(providerAddress)}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">Assessor's details</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Name</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{assessors1Name}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Phone</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{assessorsPhone}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography component="p" variant="body">Medical specialist type</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography component="p" variant="body2">{medSpecialistType}</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {renderAssessorCheckList(type, detail)}
                </Grid>
                {includeOriginalReferralFields(serviceTypeId) && referral.detail && referral.detail.originalReferralId &&
                    <React.Fragment>
                        <Grid item xs={12} sm={3}>
                            <Typography component="p" variant="body">Original referral ID</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography component="p" variant="body2">{referral.detail.originalReferralId}</Typography>
                        </Grid>
                    </React.Fragment>
                }
                {includeOriginalReferralFields(serviceTypeId) && referral.detail && referral.detail.rehabAuthorisedAssessmentCost &&
                        <React.Fragment>
                        <Grid item xs={12} sm={3}>
                            <Typography component="p" variant="body">Agreed cost and timeframes</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography component="p" variant="body2">{rehabAuthorisedAssessmentCost}</Typography>
                        </Grid>
                    </React.Fragment>
                }
                {userType === ROLE_SUPPLIER &&
                 isCode92ServiceType(serviceTypeId) &&
                    <React.Fragment>
                        <Grid item xs={12} sm={3}>
                            <Typography component="p" variant="body">Total approved hours</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography component="p" variant="body2">{rehabAssessmentTime}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography component="p" variant="body">Total approved Code 92 cost (incl. GST)</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography component="p" variant="body2">{rehabAuthorisedAssessmentCost}</Typography>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
            <Divider />
            <InjuredEmployeeDetails referral={referral}/>
            <Divider />
            <WorkplaceDetails referral={referral}/>
            <Divider />
            {(!serviceTypeId || !examineeServiceTypeIds.includes(serviceTypeId)) &&
                <React.Fragment>
                    <ManagerDetails referral={referral}/>
                    <Divider />
                    <TreatingDoctorDetails referral={referral}/>
                    <Divider />
                </React.Fragment>
            }
            <ComcareClaimDetails referral={referral}/>
            <Divider />
            <RCMDetails referral={referral}/>
            <Alert severity="info" sx={{ mt: 2 }}>
                This referral is made in accordance with the Head Agreement with Comcare Australia (SON4007568).<br/>
                The Head Agreement services and standards apply
            </Alert>
        </Box>
    );
};

export default NonCompensationDetails;
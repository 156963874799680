import React from "react";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import { getAddressText } from "./InjuredEmployeeDetails";
import { REFERRAL_TYPE_COMPENSATION } from "../../constants/referralConstants";
import dayjs from "dayjs";
  
function renderProviderContact(isRehabToContact) {
    return (
        <FormControl component="fieldset">
            <FormControlLabel
                control={<Checkbox disabled checked={isRehabToContact} size="small"/>}
                label="Assessment provider will contact employee"
            />
        </FormControl>
    );
}

export function renderAssessorCheckList(referralType, detail) {
    const {
        legallyQualifiedMedicalPractitioner : isLegallyQualified = false,
        panel: isPanel = false,
        suitablyQualifiedPerson: isSuitablyQualified = false
    } = detail || {};
  
    let qualifiedSuffix = "";
    let panelSuffix = "";
    if (referralType === REFERRAL_TYPE_COMPENSATION) {
      qualifiedSuffix = "(as described in the section 41 Guidelines for Rehabilitation Authorities 2019)";
      panelSuffix = "(as described in the section 41 Guidelines)";
    }
  
    return (
        <FormControl component="fieldset">
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox disabled checked={isLegallyQualified} size="small"/>}
                    label="Legally qualified medical practitioner"
                />
                <FormControlLabel
                    control={<Checkbox disabled checked={isSuitablyQualified} size="small"/>}
                    label={`Suitably qualified person ${qualifiedSuffix}`}
                />
                <FormControlLabel
                    control={<Checkbox disabled checked={isPanel} size="small"/>}
                    label={`Panel ${panelSuffix}`}
                />
            </FormGroup>
        </FormControl>
    )
}

const RehabAssessmentDetails = ({ referral }) => {
    const { detail, type, providerName = "" } = referral;
    const {
        assessor1Name = "",
        assessor2Name = "",
        assessor3Name = "",
        rehabAssessmentDate: rehabDate,
        rehabAssessmentTime: rehabTime,
        rehabAssessmentVenueAddress: venueAddress,
        rehabAssessmentVenueAddressSuburb: venueSuburb,
        rehabAssessmentVenueAddressState: venueState,
        rehabAssessmentVenueAddressPostcode: venuePostcode,
        rehabAssessmentVenuePhone: venuePhone,
        rehabProviderToContact: isRehabToContact = false,
        rehabAuthorisedAssessmentCost: authCost,
        medicalSpecialistName: medSpecialistType,
        caseNotes
    } = detail || {};
  
    const rehabDateText = rehabDate ? dayjs(rehabDate).format('DD MMM YYYY') : "";
    const addressText = getAddressText({
      addressLine: venueAddress,
      suburb: venueSuburb,
      state: venueState,
      postcode: venuePostcode
    });
    
    return (
        <Grid container spacing={1} sx={{ mb: 2, mt: 2 }}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">Rehabilitation assessment/examination appointment details</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Name of organisation</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{providerName}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Date</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{rehabDateText}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Time</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{rehabTime}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Address</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{addressText}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Phone/Fax</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{venuePhone}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Medical specialist type</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{medSpecialistType}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {renderAssessorCheckList(type, detail)}
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Assessor 1 name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{assessor1Name}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Assessor 2 name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{assessor2Name}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Assessor 3 name</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{assessor3Name}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {renderProviderContact(isRehabToContact)}
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography component="p" variant="body"><b>Costs</b></Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Authorised assessment costs</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{authCost}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography component="p" variant="body">Case notes</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
                <Typography component="p" variant="body2">{caseNotes}</Typography>
            </Grid>
        </Grid>
    );
}

export default RehabAssessmentDetails;
